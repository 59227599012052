<template>
  <EntityList
    ref="answersList"
    :config="config"
    :custom-query="customQuery"
    :columns="columns"
    :filter-vals="filterVals"
    :filters="filters"
    :formatted-columns="formattedColumns"
    :allow-duplicate="false"
    :export-extra-columns="{
      extraColumns: extraColumns,
      key: 'fields',
      parent: 'field',
    }"
    :extra-actions="extraActions"
    :has-insert-btn="id > 0"
    authority="Questionnaire_Management_Answer"
    single-edit
    allow-edit-columns
    desc-sort
    @filtersUpdated="filtersUpdated"
  />
</template>

<script>
import { getUserRights } from '@/auth/utils'
import EntityList from '@/layouts/entity/EntityList.vue'
import store from '@/store'
import storeModule from './storeModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      questionnaireOptions: [],
      categoryOptions: [],
      userOptions: [],
      extraColumns: [],
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    config() {
      return {
        store: storeModule,
        module: 'questionnaire-answer',
        endpoint: 'questionnaire-answer',
        route: 'answers',
        insertRoute: { name: `answers-edit`, params: { qid: this.id } },
        title: {
          single: this.$t('Questionnaire Add'),
          plural: this.$t('Questionnaires'),
        },
        editRenderCondition: {
          key: 'published',
          value: false,
        },
        deleteRenderCondition: {
          key: 'published',
          value: false,
        },
      }
    },
    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        {
          key: 'questionnaire.title',
          label: this.$t('QuestionnaireType'),
          sortable: true,
        },
        {
          key: 'patientInitials',
          label: this.$t('patientInitials'),
          sortable: true,
        },
        { key: 'user.title', label: this.$t('User'), sortable: false },
        { key: 'creationDate', label: this.$t('Date'), sortable: true },
        { key: 'publishDate', label: this.$t('Publish Date'), sortable: true },
        {
          key: 'patientCode',
          label: this.$t('patientCode'),
          visible: false,
          sortable: true,
        },
        {
          key: 'highRiskPatient',
          label: this.$t('highRiskPatient'),
          visible: false,
        },
        { key: 'comments', label: this.$t('comments'), visible: false },
        { key: 'actions', label: this.$t('actions') },
      ]
    },
    formattedColumns() {
      return [
        { key: 'creationDate', type: 'date' },
        { key: 'publishDate', type: 'date' },
      ]
    },
    customQuery() {
      if (this.id) {
        return { questionnaire: this.id }
      }
      return {}
    },
    filters() {
      let filters = [
        {
          name: 'category',
          label: this.$t('Category'),
          options: this.categoryOptions,
        },
      ]
      if (!this.id) {
        filters.push({
          name: 'questionnaire',
          label: this.$t('Questionnaire'),
          options: this.questionnaireOptions,
        })
      }
      return [
        ...filters,
        ...[
          {
            name: 'user',
            label: this.$t('User'),
            options: this.userOptions,
          },
          {
            name: 'startDate',
            type: 'date',
            label: this.$t('Start Date'),
            maxDateFieldIdx: '2',
          },
          {
            name: 'endDate',
            type: 'date',
            label: this.$t('End Date'),
            minDateFieldIdx: '1',
          },
          {
            name: 'published',
            type: 'boolean',
            label: this.$i18n.t('Published'),
          },
        ],
      ]
    },
    extraActions() {
      const isManager = getUserRights().includes('Questionnaire_Management_Questionnaire_Edit')
      return [
        {
          callback: 'getEditLink',
          icon: 'EyeIcon',
          title: 'View',
          renderCondition: {
            key: 'published',
            value: true,
          },
          propertyNotExists: 'finalized',
          authorityValidation: !isManager
        },
        {
          callback: 'getEditLink',
          icon: 'Edit3Icon',
          title: 'Edit',
          variant: 'success',
          renderCondition: {
            key: 'published',
            value: true,
          },
          propertyNotExists: 'finalized',
          authorityValidation: isManager
        },
        {
          callback: 'getEditLink',
          icon: 'EyeIcon',
          title: 'View',
          renderCondition: {
            key: 'finalized',
            value: true,
          },
          authorityValidation: isManager
        },
      ]
    },
  },
  mounted() {
    store.dispatch('app-common/fetchQuestionnaireCategories').then(response => {
      this.categoryOptions = response.data
    })
    if (!this.id) {
      store.dispatch('app-common/fetchQuestionnaires').then(response => {
        this.questionnaireOptions = response.data
        this.$refs.answersList.updateFilterValues(
          this.questionnaireOptions[0].id,
          1,
        )
      })
    } else {
      this.fetchExtraFields(this.id)
    }
    store
      .dispatch('app-common/fetchUsers', { secondarygroup: 15 })
      .then(response => {
        this.userOptions = response.data
      })
  },
  methods: {
    filtersUpdated(filters) {
      if (!this.id && filters[1]) {
        this.fetchExtraFields(filters[1])
      }
    },
    fetchExtraFields(questionnaireId) {
      store
        .dispatch('app-common/fetchQuestionnairePlainFields', questionnaireId)
        .then(response => {
          this.extraColumns = response.data
        })
    },
  },
}
</script>
